import { takeWhile } from 'lodash'
import Vue from 'vue'
import PubSub from './pubSub'

Vue.component('vue-contact-maps', {
  template: '#vue-contact-maps-template',
  data() {
    return {
      isMobile: false,
      pageWidth: null,
      breakpoints: [1280],
      office: 7,
      city: '',
      offices: [
        {
          city: 'Oddział Bydgoszcz',
          street: 'ul. Słowackiego 1',
          postCode: '85-008 Bydgoszcz',
          phone: 'tel:52 365 90 35',
          mail: 'mailto:bydgoszcz@jobimpulse.pl',
          lat: 53.13037249991279,
          lng: 18.009233353038614,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Katowice',
          street: 'ul. Mickiewicza 21',
          postCode: '40-085 Katowice',
          phone: 'tel:690 196 153',
          phone2: 'tel: 502 298 358',
          mail: 'mailto:katowice@jobimpulse.pl',
          lat: 50.262767224769064,
          lng: 19.01555291441375,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Łódź, ul. Lodowa',
          street: 'ul. Lodowa 103 (teren BSH)',
          postCode: '93-232 Łódź',
          phone: 'tel:517 811 249',
          phone2: 'tel:504 730 800',
          mail: 'mailto:bsh@jobimpulse.pl',
          lat: 51.731400914816966,
          lng: 19.514786197407968,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Łódź Piotrkowska',
          street: 'ul. Piotrkowska 147',
          postCode: '90-440 Łódź',
          phone: 'tel:42 636 34 21',
          mail: 'mailto:lodzpiotrkowska@jobimpulse.pl',
          lat: 51.76080725359298,
          lng: 19.457341540638758,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Łódź Tomaszowska (bud. A)',
          street: 'ul. Tomaszowska 7 (bud. A)',
          postCode: '93-231 Łódź',
          phone: 'tel:513 872 346',
          phone2: 'tel:503 900 990',
          mail: 'mailto:lodztomaszowska@jobimpulse.pl',
          lat: 51.733853004159435,
          lng: 19.527935669473194,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Łódź Tomaszowska (bud. B)',
          street: 'ul. Tomaszowska 7 (bud. B)',
          postCode: '93-231 Łódź',
          phone: 'tel:789 220 478',
          phone2: 'tel:505 273 648',
          mail: 'mailto:lodztomaszowska@jobimpulse.pl',
          lat: 51.733853004159435,
          lng: 19.527935669473194,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Opalenica',
          street: 'ul. Wyzwolenia 1A',
          postCode: '64-330 Opalenica',
          phone: 'tel:503 901 083',
          mail: 'mailto:opalenica@jobimpulse.pl',
          lat: 52.30844151701158,
          lng: 16.408313317601483,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Centrala Poznań',
          street: 'ul. Ptasia 10',
          postCode: '60-319 Poznań',
          phone: 'tel:61 662 34 00',
          mail: 'mailto:centrala@jobimpulse.pl',
          mail2: 'mailto:iodo@jobimpulse.pl',
          lat: 52.39985904191973,
          lng: 16.861551698327272,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Poznań',
          street: 'ul. Grunwaldzka 21',
          postCode: '60-783 Poznań',
          phone: 'tel:517 945 597',
          mail: 'mailto:poznan@jobimpulse.pl',
          lat: 52.40516606921497,
          lng: 16.89738489832751,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Pruszków',
          street: 'ul. Anielin 1 lok. 5',
          postCode: '05-800 Pruszków',
          phone: 'tel:789 220 148',
          mail: 'mailto:pruszkow@jobimpulse.pl',
          lat: 52.16704555922666,
          lng: 20.8074841685932,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Szczecin',
          street: 'ul. Piastów 4',
          postCode: '70-325 Szczecin',
          phone: 'tel:512 097 796',
          mail: 'mailto:szczecin@jobimpulse.pl',
          lat: 53.43253379911691,
          lng: 14.539027676907526,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Tczew',
          street: 'ul. Armii Krajowej 86 pok. 9',
          postCode: '83-110 Tczew',
          phone: 'tel:512 093 413',
          mail: 'mailto:tczew@jobimpulse.pl',
          lat: 54.09435233990349,
          lng: 18.757665069540742,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Warszawa',
          street: 'ul. Jana Pawła II 29 budynek Atrium Plaza, wejście od ul. Elektoralnej',
          postCode: '00-867 Warszawa',
          phone: 'tel:22 620 21 17',
          mail: 'mailto:warszawa@jobimpulse.pl',
          lat: 52.23911726196036,
          lng: 20.99466989743199,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
        {
          city: 'Oddział Wrocław',
          street: 'ul. Kościuszki 16/3',
          postCode: '50-038 Wrocław',
          phone: 'tel:690 481 195',
          mail: 'mailto:wroclaw@jobimpulse.pl',
          lat: 51.10327935249598,
          lng: 17.028761663913144,
          openHours: ['pn - pt: 8:00 - 16:00'],
        },
      ],
      mapOptions: {},
    }
  },
  methods: {
    addResizeLstener() {
      this.pageWidth = document.documentElement.clientWidth
      PubSub.on('resize', this.watchBreakpoints)
    },
    breakpointCrossed(breakpoint, currentPageWidth) {
      const previousPageWidth = this.pageWidth
      return (
        (previousPageWidth < breakpoint && currentPageWidth >= breakpoint) ||
        (previousPageWidth >= breakpoint && currentPageWidth < breakpoint)
      )
    },
    handleChange() {
      this.setMapMarker()
      this.changeMapCenter()
    },
    setMapMarker() {
      this.marker.setPosition({
        lat: this.offices[this.office].lat,
        lng: this.offices[this.office].lng,
      })
      this.marker.setTitle(this.offices[this.office].city)
      this.marker.setMap(this.map)
    },
    setIsMobile(breakpoint, currentPageWidth) {
      if (currentPageWidth < breakpoint) this.isMobile = true
      else this.isMobile = false
    },
    setmapOptions() {
      this.mapOptions = {
        center: { lat: this.offices[this.office].lat, lng: this.offices[this.office].lng },
        zoom: 15,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      }
    },
    changeMapCenter() {
      this.map.setCenter({ lat: this.offices[this.office].lat, lng: this.offices[this.office].lng })
    },
    watchBreakpoints() {
      const currentPageWidth = document.documentElement.clientWidth
      this.breakpoints.forEach(breakpoint => {
        if (!this.breakpointCrossed(breakpoint, currentPageWidth)) return
        this.setIsMobile(breakpoint, currentPageWidth)
        this.pageWidth = currentPageWidth
      })
    },
    initMap() {
      this.mapElement = document.getElementById('map')
      this.map = new google.maps.Map(document.getElementById('map'), this.mapOptions)
      this.marker = new google.maps.Marker({
        position: { lat: this.offices[this.office].lat, lng: this.offices[this.office].lng },
        title: this.offices[this.office].city,
      })
      this.marker.setMap(this.map)
    },
  },
  created() {
    this.addResizeLstener()
    this.setIsMobile(this.breakpoints[0], this.pageWidth)
    this.setmapOptions()
  },
  mounted() {
    this.$nextTick(this.initMap())
  },
})

const contact = document.querySelectorAll('#vue-contact-maps-wrapper')
window.addEventListener('DOMContentLoaded', () => {
  if (!contact) return
  contact.forEach(element => {
    new Vue({
      el: element,
    })
  })
})
